<template>
  <CBox>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
      <!-- <HeaderPremium />
      <Breadcrumb />
      <CStack
        display="flex"
        direction="row"
        :spacing="6"
        mt="20px"
        mb="50px"
        >
        <SidebarDashboard :currentUser="currentUser" /> -->
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox">
          <CBox mx="32px" mt="20px">
            <CBox d="flex" justifyContent="space-between">
              <CBox d="table-cell" verticalAlign="middle" h="inherit" lineHeight="14px" my="auto">
                <CText fontWeight="bolder" fontSize="20px" color="fontMain">Web CV</CText>
              </CBox>
              <CBox align="right">
                <ButtonBlue @click.native="$router.push({ name: 'Choose Web CV Wizard' })" label="Ubah Profil" icon="icon-setting.png" style="align: right;" />
                <CText color="fontInformation" align="right" fontSize="12px" mt="5px">Sesuaikan data web CV kamu? Klik disini</CText>
              </CBox>
            </CBox>
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" />
          <CBox mx="32px" my="20px">
            <CBox>
              <CText color="fontMain" fontWeight="bolder">Desain Web CV Terpilih</CText>
              <CImage v-if="currentUser.web_cv_design_converted" :src="require(`@/assets/web-cv/web-cv-${currentUser.web_cv_design_converted}.png`)" w="100%" mt="15px" h="389px" objectFit="cover" objectPosition="top" />
              <CBox v-else h="389px">
                <Loading />
              </CBox>
            </CBox>
            <CBox d="flex" mt="15px">
              <ButtonBlue label="Pilih Desain" @click.native="$router.push({ name: 'Choose Web CV' })" />
              <ButtonSecondary @click.native="openWebcv()" label="Lihat halaman Web anda" style="margin-left: 10px;" />
            </CBox>
          </CBox>
        </CBox>
      <!-- </CStack> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER } from "@/store/auth.module"
// import SidebarDashboard from "@/components/sidebar/SidebarDashboard.vue"
// import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue"
// import HeaderPremium from "@/components/header/HeaderPremium.vue"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
import ButtonSecondary from "@/components/button/ButtonSecondary.vue"
import Loading from "@/components/Loading.vue";
import config from "@/ConfigProvider";
import Swal from "sweetalert2";

export default {
  metaInfo: {
    title: "Web CV",
    titleTemplate: "%s - Gethired.id"
  },
  props: {
    currentUser: {
      type: Object,
      default() {
        return {}
      },
    }
  },
  components: {
    // SidebarDashboard,
    // Breadcrumb,
    // HeaderPremium,
    ButtonBlue,
    ButtonSecondary,
    Loading
  },
  data() {
    return {
      webcv_url: config.value("WEBCV_URL")
    }
  },
  mounted() {
    if (this.$route.query.payment == "success") {
      Swal.fire({
        title: "Pengaturan Web CV",
        text: "Selamat, kini kamu bisa menyesuaikan data profile, video biografi serta keahlian yang ingin kamu tampilkan pada WEB CV mu.",
        heightAuto: true,
        willClose: () => {
          this.$router.push({ name: "Choose Web CV Wizard" });
        },
      });
    } else {
      this.getData();
    }
  },
  computed: {
    // currentUser() {
    //   const user = this.$store.getters.currentUser;
    //   return user;
    // },
  },
  methods: {
    getData() {
      // setTimeout(async () => {
        this.$store.dispatch(GET_USER)
      // }, 100);
    },
    openWebcv() {
      window.open(this.webcv_url+'/'+this.currentUser.emp_username, '_blank')
    }
  }
}
</script>
