<template>
  <CBox :mt="mt">
    <CButton v-if="!iconPosition" :size="size" :disabled="disabled" bg="activeMenu" color="white" :_hover="{ bg: 'activeMenu' }" :rounded="rounded" :height="`calc(${heightDefault[size]} + ${height})`" :px="`calc(${paddingDefault[size]} + ${padding})`">
      <CImage v-if="icon" :src="require(`@/assets/icon/${icon}`)" w="18px" pr="5px" />
      {{ label }}
    </CButton>
    <CButton v-if="iconPosition == 'left'" :left-icon="icon" :size="size" :disabled="disabled" bg="activeMenu" color="white" :_hover="{ bg: 'activeMenu' }">
      {{ label }}
    </CButton>
    <CButton v-if="iconPosition == 'right'" :right-icon="icon" :size="size" :disabled="disabled" bg="activeMenu" color="white" :_hover="{ bg: 'activeMenu' }">
      {{ label }}
    </CButton>
  </CBox>
</template>

<script>
import { CButton } from "@chakra-ui/vue"

export default {
  name: "ButtonBlue",
  components: {
    CButton
  },
  props: { 
    label: {
      type: String,
      default: "Button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "md"
    },
    iconPosition: {
      type: String,
      default: ""
    },
    mt: {
      type: String,
      default: "0px"
    },
    rounded: {
      type: String,
      default: "5px",
    },
    height: {
      type: String,
      default: "0rem"
    },
    padding: {
      type: String,
      default: "0rem"
    }
  },
  data() {
    return {
      heightDefault: {
        lg: "3rem",
        md: "2.5rem",
        sm: "2rem",
        xs: "1.5rem"
      },
      paddingDefault: {
        lg: "1.5rem",
        md: "1rem",
        sm: "0.75rem",
        xs: "0.5rem"
      }
    }
  }
}

</script>